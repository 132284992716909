import Button from '@components/atoms/button'
import useResolution from '@hooks/useResolution'

type CodingGuideType = {
  title: string
  link: string
}
const CODING_GUIDES: CodingGuideType[] = [
  {
    title: 'Coding 101 for kids',
    link:
      'https://www.codingal.com/coding-for-kids/coding-guides/coding-101-the-ultimate-guide/',
  },
  {
    title: 'How can kids get started with coding',
    link:
      'https://www.codingal.com/coding-for-kids/coding-guides/how-can-kids-get-started-with-coding/',
  },
  {
    title: 'Minecraft – a game or a coding platform',
    link:
      'https://www.codingal.com/resources/coding-guides/minecraft-a-game-or-coding-platform/',
  },
  {
    title: 'Block Coding – An A To Z Guide',
    link:
      'https://www.codingal.com/resources/coding-guides/block-coding-guide/',
  },
  {
    title: 'Women In STEM',
    link:
      'https://www.codingal.com/resources/coding-guides/women-in-stem-all-you-need-to-know/',
  },
  {
    title: 'Python for Kids: The Ultimate Guide to Learn Python',
    link:
      'https://www.codingal.com/resources/coding-guides/python-programming-for-kids-the-ultimate-guide-to-learn-python/',
  },
]

const CodingGuides = () => {
  const { sizes } = useResolution()
  return (
    <div
      className="text-gre leading-tight mx-2 lg:container lg:mx-auto mt-20 border border-blue-100 shadow-2d rounded-xl lg:p-10"
      id="coding-guides"
      style={{ backgroundColor: '#EFF8FF' }}
    >
      <div className="p-5">
        <h2 className="text-2xl lg:text-4xl font-700 mb-2 lg:mb-4">
          Coding guides
        </h2>
        <p className="mb-2 lg:mb-5 text-lg lg:text-xl">
          Coding guides to get an in-depth information about coding topics for
          kids.
        </p>
      </div>
      <ul className="flex flex-col gap-y-1 lg:gap-y-5 font-600 text-xl pl-0 lg:pl-10 list-none lg:list-disc">
        {CODING_GUIDES.map((guide) => (
          <li
            key={guide.title}
            className="text-orange px-5 py-3 lg:p-0 hover:underline"
            style={{ backgroundColor: sizes.sm ? '#DDF0FE' : '#EFF8FF' }}
          >
            <a href={guide.link} target="_blank" rel="noreferrer">
              {guide.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="p-5 lg:px-5 lg:pt-10 flex">
        <a
          href="https://www.codingal.com/resources/coding-guides/"
          target="_blank"
          rel="noreferrer"
          className="w-full lg:w-auto"
        >
          <Button className="w-full">Read more guides</Button>
        </a>
      </div>
    </div>
  )
}

export default CodingGuides
