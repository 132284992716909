import Button from '@components/atoms/button'

type CodingBlogType = {
  title: string
  link: string
}
const CODING_BLOGS: CodingBlogType[] = [
  {
    title: 'Top 10 coding resources for kids',
    link: 'https://www.codingal.com/blog/coding/top-coding-resources-for-kids/',
  },
  {
    title: 'Introduction to Scratch coding',
    link:
      'https://www.codingal.com/coding-for-kids/blog/introduction-to-scratch-coding/',
  },
  {
    title: '10 Best Scratch Projects for Kids',
    link:
      'https://www.codingal.com/blog/coding/scratch-for-kids-projects-beginners/',
  },
  {
    title: 'How to make animation in Scratch',
    link:
      'https://www.codingal.com/coding-for-kids/blog/how-to-make-animation-in-scratch/',
  },
  {
    title: 'How to create Space Invaders game using Python',
    link:
      'https://www.codingal.com/coding-for-kids/blog/space-invaders-game-using-python/',
  },
  {
    title: 'best Roblox games',
    link: 'https://www.codingal.com/coding-for-kids/blog/7-best-roblox-games/',
  },
  {
    title: '7 best games in Scratch',
    link:
      'https://www.codingal.com/coding-for-kids/blog/7-best-games-in-scratch/',
  },
]

const CodingBlogs = () => {
  return (
    <div
      className="text-grey leading-tight mx-2 lg:container lg:mx-auto mt-20 bg-orange-100 border border-orange-300 shadow-2d rounded-xl lg:p-10"
      id="coding-blogs"
    >
      <div className="p-5">
        <h2 className="text-2xl lg:text-4xl font-700 mb-2 lg:mb-4">
          Coding blogs
        </h2>
        <p className="mb-2 lg:mb-5 text-lg lg:text-xl">
          Free reading resources for parents to help them start learning about
          coding for kids.
        </p>
      </div>
      <ul className="flex flex-col gap-y-1 lg:gap-y-5 font-600 text-xl pl-0 lg:pl-10 list-none lg:list-disc">
        {CODING_BLOGS.map((blog) => (
          <li
            key={blog.title}
            className="text-orange bg-orange-200 lg:bg-orange-100 px-5 py-3 lg:p-0 hover:underline"
          >
            <a href={blog.link} target="_blank" rel="noreferrer">
              {blog.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="p-5 lg:px-5 lg:pt-10 flex">
        <a
          href="https://www.codingal.com/blog/"
          target="_blank"
          rel="noreferrer"
          className="w-full lg:w-auto"
        >
          <Button className="w-full">Read more blogs</Button>
        </a>
      </div>
    </div>
  )
}

export default CodingBlogs
