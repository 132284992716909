import Button from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'

const IMAGE_PATH = '/images/coding-for-kids/'

const CodingWorkSheets = () => {
  return (
    <div
      className="flex flex-col lg:flex-row container items-center mx-auto lg:space-x-16 text-grey mt-10 lg:mt-20 px-5"
      id="coding-worksheets"
    >
      <div className="relative">
        <FastlyImage
          src={`${IMAGE_PATH}diy-coding-worksheet-picture.png`}
          height={500}
          width={650}
          alt="Free coding worksheets for kids"
        />
      </div>
      <div>
        <h2 className="text-2xl lg:text-4xl font-700">Coding worksheets</h2>
        <p className="text-xl leading-tight mt-4 mb-6">
          These worksheets help kids start exercising their brains with coding
          problems & equations, drawing & arranging pictures in a logical
          sequence, etc.
        </p>
        <a
          href="https://www.codingal.com/resources/diy-worksheets/"
          rel="noopenner noreferrer"
          target="_blank"
          className="lg:self-start w-full lg:w-auto"
        >
          <Button variant="outline" className="w-full lg:w-auto">
            Download worksheets now
          </Button>
        </a>
      </div>
    </div>
  )
}
export default CodingWorkSheets
