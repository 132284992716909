import Button from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'

const IMAGE_PATH = '/images/coding-for-kids/'

const CodingMasterClasses = () => {
  return (
    <div
      className="flex flex-col lg:flex-row-reverse container items-center mx-auto gap-x-10 text-grey mt-10 lg:mt-20 px-5"
      id="coding-masterclasses"
    >
      <div className="relative">
        <FastlyImage
          src={`${IMAGE_PATH}coding-masterclasses.png`}
          height={500}
          width={650}
          alt="Free online coding masterclasses for kids"
        />
      </div>
      <div className="ml-0 w-full">
        <h2 className="text-2xl lg:text-4xl font-700">Coding masterclasses</h2>
        <p className="text-xl leading-tight mt-4 mb-6">
          Free online masterclasses for kids to learn about animation, game
          development, application building, web development, and more.
        </p>
        <a
          href="https://www.codingal.com/resources/masterclasses/"
          rel="noopenner noreferrer"
          target="_blank"
          className="lg:self-start w-full lg:w-auto"
        >
          <Button variant="outline" className="w-full lg:w-auto">
            Register for free
          </Button>
        </a>
      </div>
    </div>
  )
}
export default CodingMasterClasses
